/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* STYLING */

/* General */

.blog-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: 1fr;
	gap: 50px 50px;
	margin: 0px 50px;

	margin-bottom: 3rem;
}

.blog-block {
	background-color: rgb(127, 179, 224);
	height: 200px;
	border-radius: 10px;
	cursor: pointer;
	box-shadow: 0px 0px 10px #d8d5d5;

	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.blogpost-title {
	color: white;
	text-transform: uppercase;
	font-family: forma-djr-deck, sans-serif;
	font-size: 2rem;
	line-height: 2.2rem;
}

/* Home page */

.header {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin: 5rem 0;
}

.blog-heading {
	font-family: ff-nexus-typewriter, sans-serif;
	font-size: 5rem;
}

.blog-subheading {
	font-family: forma-djr-deck, sans-serif;
	font-size: 2rem;
	margin-top: 2rem;
}

/* Blog post */

.blogpost {
	display: grid;
	grid-template-columns: 0.5fr 1fr 0.5fr;
	grid-template-areas: 
	". blogcont ."
	;
	margin: 2.5rem 0;
}

.blogpost-content {
	font-family: adonis-web, serif;
	font-size: 1.5rem;
	grid-area: blogcont;
	line-height: 1.75rem;
}

.blogpost-content h2 {
	font-weight: 500;
	font-size: 3rem;

	margin-bottom: 2rem;
	margin-top: 3rem;
}

.blogpost-content p {
	margin-bottom: 2rem;
}

/* Back button */

.backbutton {
	position: fixed;
	top: 0;
	color: white;
	border: none;
	border-radius: 25px;
	background-color: rgb(127, 179, 224);
	cursor: pointer;
	padding: 0.5rem 1.5rem;
	margin: 1rem;

	font-size: 1.5rem;
	font-family: forma-djr-deck, sans-serif;
}

/* Footer */

footer {
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgb(192, 223, 250);
}

.footer-text {
	font-family: ff-nexus-typewriter, sans-serif;
	margin: 2rem 0;
}

@media (max-width: 900px) {

	.backbutton {
		top: 90vh;
	}

	.blogpost {
		grid-template-columns: 0.1fr 1fr 0.1fr;
		margin-top: 0;
	}

	.blog-grid {
		grid-template-columns: repeat(2, 1fr);
	}

	.blog-heading {
		font-size: 4rem;
	}

	.blog-subheading {
		font-size: 1.2rem;
	}
}

@media (max-width: 500px) {
	.blog-grid {
		grid-template-columns: 1fr;
	}

	.blogpost-content h2 {
		line-height: 3.5rem;
	}
}